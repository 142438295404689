@import url("https://fonts.googleapis.com/css2?family=Source+Sans+3:ital,wght@0,200..900;1,200..900&display=swap");
/* @import url("https://fonts.googleapis.com/css2?family=Simonetta:ital,wght@0,400;0,900;1,400;1,900&display=swap"); */
@import url("https://fonts.googleapis.com/css2?family=Yanone+Kaffeesatz:wght@200..700&display=swap");

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  html {
    font-family: "source sans 3", sans-serif;
    line-height: 1.3;
  }

  .yanone-kaffeesatz {
    font-family: "Yanone Kaffeesatz", sans-serif;
    font-optical-sizing: auto;
    font-weight: 700;
    font-style: normal;
  }

  .simonetta-regular {
    font-family: "Simonetta", serif;
    font-weight: 400;
    font-style: normal;
  }

  .simonetta-black {
    font-family: "Simonetta", serif;
    font-weight: 900;
    font-style: normal;
  }

  .simonetta-regular-italic {
    font-family: "Simonetta", serif;
    font-weight: 400;
    font-style: italic;
  }

  .simonetta-black-italic {
    font-family: "Simonetta", serif;
    font-weight: 900;
    font-style: italic;
  }

  table {
  }

  th,
  td {
    @apply py-3 px-2.5 text-sm text-left border-y border-gray-200;
  }
  thead {
    @apply bg-neutral-100;
  }
  th {
    @apply font-semibold;
  }
  a {
    @apply text-sky-500 hover:underline;
  }

  .buttonPrimary {
    @apply bg-brandPrimary font-medium text-white py-2 px-7 rounded-sm hover:bg-neutralDGray transition-all duration-300 hover:-translate-y-4;
  }

  .buttonNews {
    @apply bg-neutralDGray font-medium text-white py-2 px-7 rounded-sm hover:bg-neutralDGray transition-all duration-300 hover:-translate-y-4;
  }
}
